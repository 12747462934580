import service from '@/components/Table/service'
import { onMounted, ref } from '@vue/composition-api'
import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { createToastInterface } from 'vue-toastification'
import { serializeErrorMessage } from '@core/utils/utils'

export default function useReturnOrder(returnSalesId = null) {
	const resolveInvoiceStatusVariantAndIcon = status => {
		if (status === 'UNPAID') return { variant: 'warning', icon: 'XIcon' }
		if (status === 'Proses') return { variant: 'info', icon: 'PieChartIcon' }
		if (status === 'PAID') return { variant: 'success', icon: 'CheckCircleIcon' }
		return { variant: 'secondary', icon: 'XIcon' }
	}

	const isLoading = ref(true)

	const returnOrderDetail = ref(null)
	const returnOrderDetailTemp = ref(null)
	const retrieveReturnOrder = id => {
		isLoading.value = true
		service
			.show({ url: 'return-sales', id })
			.then(response => {
				if (response.status === 200) {
					returnOrderDetail.value = response.data
					returnOrderDetailTemp.value = JSON.parse(JSON.stringify(response.data))
					// console.log(returnOrderDetail.value)
				}
			})
			.catch(error => {
				const element = Vue.component('description', {
					render: h => serializeErrorMessage(h, error),
				})
				const toast = createToastInterface({
					timeout: 6000,
				})
				toast({
					component: ToastificationContent,
					props: {
						title: 'Error Retrieve The Order',
						icon: 'AlertTriangleIcon',
						variant: 'danger',
						description: element,
					},
				})
			})
			.finally(() => {
				isLoading.value = false
			})
	}

	const calcTotal = item => {
		const total = (parseFloat(item.price)
			- parseFloat(item.price) * (parseFloat(item.discount) / 100))
			* parseInt(item.quantity)
		return total
	}

	const subTotal = items => {
		let total = 0
		items.forEach(item => {
			// const t =
			// 	(parseFloat(item.price) -
			// 		parseFloat(item.price) * (parseFloat(item.discount) / 100)) *
			// 	parseInt(item.quantity)
			const t = calcTotal(item)
			total += t
		})
		return total
	}

	const total = returnOrderDetail => {
		let total = subTotal(returnOrderDetail.items)
		let voucherValue = returnOrderDetail.voucher_usage !== null
			? parseFloat(returnOrderDetail.voucher_usage.value)
			: 0

		if (
			returnOrderDetail.voucher_usage !== null
			&& returnOrderDetail.voucher_usage.type === 'percentage'
		) {
			voucherValue = total - total * (voucherValue / 100)
		}
		total = total + parseFloat(returnOrderDetail.shipment.shipment_price) - voucherValue
		return total
	}

	const loadingShipmentSend = ref(false)

	const showShipmentModal = ref(false)
	const shipmentSubmit = shipmentPayload => {
		const data = {
			...shipmentPayload,
			order_id: returnSalesId,
		}
		loadingShipmentSend.value = true
		service.store({ url: 'shipment/create', data }).then(response => {
			retrieveReturnOrder(returnSalesId)
			showShipmentModal.value = false
		}).catch(error => {
			const element = Vue.component('description', {
				render: h => serializeErrorMessage(h, error),
			})
			const toast = createToastInterface({
				timeout: 6000,
			})
			toast({
				component: ToastificationContent,
				props: {
					title: 'Error Retrieve The Order',
					icon: 'AlertTriangleIcon',
					variant: 'danger',
					description: element,
				},
			})
		}).finally(() => {
			loadingShipmentSend.value = false
		})
	}

	const trackingHIstoryData = ref(null)
	const trackingModal = ref(false)
	const getTrackingHistory = returnSalesId => {
		loadingShipmentSend.value = true
		service.show({ url: 'shipment/tracking', id: returnSalesId }).then(response => {
			trackingHIstoryData.value = response.data
			trackingModal.value = true
		}).catch(error => {
			const element = Vue.component('description', {
				render: h => serializeErrorMessage(h, error),
			})
			const toast = createToastInterface({
				timeout: 6000,
			})
			toast({
				component: ToastificationContent,
				props: {
					title: 'Error Retrieve The Order',
					icon: 'AlertTriangleIcon',
					variant: 'danger',
					description: element,
				},
			})
		}).finally(() => {
			loadingShipmentSend.value = false
		})
	}

	if (returnSalesId) {
		onMounted(() => {
			retrieveReturnOrder(returnSalesId)
		})
	}

	const rejectModal = ref(false)
	const rejectLoading = ref(false)
	const reject = payload => {
		rejectLoading.value = true
		service.store({ url: 'order/cancel_order', data: payload }).then(response => {
			rejectModal.value = false
			retrieveReturnOrder(returnSalesId)
		}).catch(error => {
			const element = Vue.component('description', {
				render: h => serializeErrorMessage(h, error),
			})
			const toast = createToastInterface({
				timeout: 6000,
			})
			toast({
				component: ToastificationContent,
				props: {
					title: 'Error Retrieve The Order',
					icon: 'AlertTriangleIcon',
					variant: 'danger',
					description: element,
				},
			})
		}).finally(() => {
			rejectLoading.value = false
		})
	}

	const refundModal = ref(false)
	const refundLoading = ref(false)
	const refund = payload => {
		refundLoading.value = true
		service.store({ url: 'return-sale-refund', data: payload }).then(response => {
			refundModal.value = false
			retrieveReturnOrder(returnSalesId)
		}).catch(error => {
			const element = Vue.component('description', {
				render: h => serializeErrorMessage(h, error),
			})
			const toast = createToastInterface({
				timeout: 6000,
			})
			toast({
				component: ToastificationContent,
				props: {
					title: 'Error Retrieve The Order',
					icon: 'AlertTriangleIcon',
					variant: 'danger',
					description: element,
				},
			})
			refundModal.value = false
		}).finally(() => {
			refundLoading.value = false
		})
	}

	const processLoading = ref(false)
	const receiveModal = ref(false)
	const receiveForm = ref({ warehouse_id: null, return_sale_id: null })
	const receiveAsStock = payload => {
		processLoading.value = true
		service.store({ url: 'return-sale-receive', data: payload }).then(response => {
			receiveModal.value = false
			retrieveReturnOrder(returnSalesId)
		}).catch(error => {
			const element = Vue.component('description', {
				render: h => serializeErrorMessage(h, error),
			})
			const toast = createToastInterface({
				timeout: 6000,
			})
			toast({
				component: ToastificationContent,
				props: {
					title: 'Error Retrieve The Order',
					icon: 'AlertTriangleIcon',
					variant: 'danger',
					description: element,
				},
			})
		}).finally(() => {
			processLoading.value = false
		})
	}

	const wareHousesData = ref()
	const resetReceiveForm = {
		warehouse_id: null,
		return_sale_id: null,
	}
	const fetchWarehouse = () => {
		service.index({ url: 'warehouse-commerce', query: 'sort_by=warehouse_name&direction=asc' }).then(response => {
			wareHousesData.value = response.data.data
		}).catch(error => {
			const element = Vue.component('description', {
				render: h => serializeErrorMessage(h, error),
			})
			const toast = createToastInterface({
				timeout: 6000,
			})
			toast({
				component: ToastificationContent,
				props: {
					title: 'Error Retrieve The Return Order',
					icon: 'AlertTriangleIcon',
					variant: 'danger',
					description: element,
				},
			})
		})
	}

	const confirmModal = ref(false)
	const confirmLoading = ref(false)
	const confirmRetur = () => {
		confirmLoading.value = true
		service.store({ url: 'return-sale-process', data: { id: returnSalesId } }).then(response => {
			retrieveReturnOrder(returnSalesId)
			confirmModal.value = false
		}).catch(error => {
			console.log(error)
			const element = Vue.component('description', {
				render: h => serializeErrorMessage(h, error),
			})
			const toast = createToastInterface({
				timeout: 6000,
			})
			toast({
				component: ToastificationContent,
				props: {
					title: 'Error Retrieve The Return Order',
					icon: 'AlertTriangleIcon',
					variant: 'danger',
					description: element,
				},
			})
		}).finally(() => {
			confirmLoading.value = false
		})
	}
	onMounted(() => {
		fetchWarehouse()
	})

	const fullfilmentModal = ref(false)
	const fullfillmentFormTemp = ref(null)
	const fullfillmentLoading = ref(false)
	const fullFillmentResetForm = ref({
		id: null,
		return_sale_id: null,
		kd_produk: null,
		quantity: null,
		reason: null,
		deleted_at: null,
		created_at: null,
		updated_at: null,
		fullfillment_quantity_retur: null,
		images: [],
	})
	const fullfillment = data => {
		fullfillmentLoading.value = true
		service.store({ url: 'return-sale-fullfillment', data: { detail_id: data.id, quantity: data.fullfillment_quantity_retur } }).then(response => {
			retrieveReturnOrder(returnSalesId)
			fullfilmentModal.value = false
		}).catch(error => {
			const element = Vue.component('description', {
				render: h => serializeErrorMessage(h, error),
			})
			const toast = createToastInterface({
				timeout: 6000,
			})
			toast({
				component: ToastificationContent,
				props: {
					title: 'Error do Fullfillment',
					icon: 'AlertTriangleIcon',
					variant: 'danger',
					description: element,
				},
			})
		}).finally(() => {
			fullfillmentLoading.value = false
		})
	}

	const orderDetail = ref(null)
	const showRefundModal = () => {
		isLoading.value = true
		service.index({ url: 'order-detail', query: `order_id=${returnOrderDetail.value.order_id}` })
			.then(response => {
				orderDetail.value = response.data
				refundModal.value = true
			})
			.catch(error => {
				const element = Vue.component('description', {
					render: h => serializeErrorMessage(h, error),
				})
				const toast = createToastInterface({
					timeout: 6000,
				})
				toast({
					component: ToastificationContent,
					props: {
						title: 'Error Retrieve The Order',
						icon: 'AlertTriangleIcon',
						variant: 'danger',
						description: element,
					},
				})
			})
			.finally(() => {
				isLoading.value = false
			})
	}

	return {
		resolveInvoiceStatusVariantAndIcon,
		retrieveReturnOrder,
		returnOrderDetail,
		calcTotal,
		subTotal,
		total,
		isLoading,
		shipmentSubmit,
		loadingShipmentSend,
		getTrackingHistory,
		trackingHIstoryData,
		trackingModal,
		reject,
		rejectLoading,
		rejectModal,
		refundLoading,
		refund,
		refundModal,
		receiveAsStock,
		processLoading,
		receiveModal,
		showShipmentModal,
		wareHousesData,
		resetReceiveForm,
		receiveForm,
		confirmModal,
		confirmRetur,
		confirmLoading,
		fullfillment,
		fullfillmentFormTemp,
		fullfillmentLoading,
		fullfilmentModal,
		fullFillmentResetForm,
		returnOrderDetailTemp,
		showRefundModal,
		orderDetail,
	}
}
