<template>
  <b-overlay id="overlay-background" spinner-variant="primary" :show="isLoading" variant="transparent" opacity="1.00"
    blur="0.5em" rounded="sm" class="w-100">
    <b-row v-if="returnOrderDetail">
      <b-col cols="12">
        <b-card>
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <p class="m-0">Retur Status:</p>
              <b-badge :variant="statusOrderVariant[returnOrderDetail.status]">
                <span class="m-0" style="font-size: 1rem">{{
                  statusOrder[returnOrderDetail.status]
                }}</span>
              </b-badge>
            </div>
            <div>
              <a v-if="returnOrderDetail !== null" class="ml-1 btn btn-info" target="_blank" :href="`https://wa.me/${waNumberFormat(
                returnOrderDetail.order.shipment.receiver_phone
              )}`">
                <i class="fa fa-whatsapp" />Hubungi
              </a>
              <b-button v-if="returnOrderDetail.status === 'PENDING'" class="ml-1" variant="primary"
                @click="confirmModal = true">Konfirmasi/Proses</b-button>
              <b-button v-if="returnOrderDetail.warehouse === null &&
                returnOrderDetail.courier_name !== null &&
                returnOrderDetail.no_resi !== null
                " class="ml-1" variant="primary" @click="receiveModal = true">Receive</b-button>
              <b-button v-if="returnOrderDetail.warehouse !== null &&
                canSend &&
                returnOrderDetail.is_refund === false
                " class="ml-1" :disabled="loadingShipmentSend" variant="success"
                @click="showShipmentModal = true"><span>Kirim</span>
              </b-button>
              <b-button v-if="returnOrderDetail.warehouse !== null &&
                !canSend &&
                returnOrderDetail.is_refund === false
                " class="ml-1" variant="warning" @click="showRefundModal()"><span>Refund</span>
              </b-button>
              <b-button v-if="returnOrderDetail.warehouse === null && returnOrderDetail.courier_name === null && returnOrderDetail.no_resi === null" class="ml-1" variant="danger"
                @click="rejectModal = true">
                Reject
              </b-button>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col cols="12" md="6">
        <b-card>
          <b-row>
            <b-col cols="4" class="text-right d-flex align-items-center justify-content-end mb-1">
              <span class="font-weight-bolder">No. Retur</span>
            </b-col>
            <b-col cols="8" class="mb-1">
              <span class="font-weight-bolder">{{
                returnOrderDetail.number
              }}</span>
            </b-col>
            <b-col cols="4" class="text-right d-flex align-items-center justify-content-end mb-1">
              <span class="font-weight-bolder">Retur Status</span>
            </b-col>
            <b-col cols="8" class="mb-1">
              <b-form-input v-model="returnOrderDetail.status" disabled placeholder="Payment Status" />
            </b-col>
            <b-col cols="4" class="text-right d-flex align-items-center justify-content-end mb-1">
              <span class="font-weight-bolder">Received Warehouse</span>
            </b-col>
            <b-col cols="8" class="mb-1">
              <span>{{
                returnOrderDetail.warehouse
                ? returnOrderDetail.warehouse.warehouse_name
                : 'UNSET'
              }}</span>
              <b-button v-if="returnOrderDetail.warehouse !== null" variant="link"
                @click="receiveModal = true">Change</b-button>
            </b-col>
            <b-col cols="4" class="text-right d-flex align-items-center justify-content-end mb-1">
              <span class="font-weight-bolder">Nama Jasa Kurir</span>
            </b-col>
            <b-col cols="8" class="mb-1">
              <span>{{
                returnOrderDetail.courier_name
                ? returnOrderDetail.courier_name
                : '-'
              }}</span>
            </b-col>
            <b-col cols="4" class="text-right d-flex align-items-center justify-content-end mb-1">
              <span class="font-weight-bolder">No. Resi</span>
            </b-col>
            <b-col cols="8" class="mb-1">
              <span>{{
                returnOrderDetail.no_resi ? returnOrderDetail.no_resi : '-'
              }}</span>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col cols="12" md="6">
        <b-card>
          <b-row>
            <b-col cols="4" class="text-right d-flex align-items-center justify-content-end mb-1">
              <span class="font-weight-bolder">No. Invoice</span>
            </b-col>
            <b-col cols="8" class="mb-1">
              <b-link :to="{
                  name: 'order-detail',
                  params: { id: returnOrderDetail.order_id },
                }" class="font-weight-bolder">{{ returnOrderDetail.order.transaction.no_invoice }}</b-link>
            </b-col>
            <b-col cols="4" class="text-right d-flex align-items-center justify-content-end mb-1">
              <span class="font-weight-bolder">Nama Customer</span>
            </b-col>
            <b-col cols="8" class="mb-1">
              <router-link :to="{
                name: 'apps-users-view',
                params: { id: returnOrderDetail.order.user_id },
              }">
                <span>{{ returnOrderDetail.order.user.nama_customer }}</span>
              </router-link>
            </b-col>
            <b-col cols="4" class="text-right d-flex align-items-center justify-content-end mb-1">
              <span class="font-weight-bolder">Email</span>
            </b-col>
            <b-col cols="8" class="mb-1">
              <span>{{ returnOrderDetail.order.user.email }}</span>
            </b-col>
            <b-col cols="4" class="text-right d-flex align-items-center justify-content-end mb-1">
              <span class="font-weight-bolder">No. Hp</span>
            </b-col>
            <b-col cols="8" class="mb-1">
              <span>{{ returnOrderDetail.order.user.no_hp }}</span>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col cols="12">
        <b-card>
          <b-card-title> Produk </b-card-title>
          <b-table responsive :items="returnOrderDetail.details" :fields="itemsColumn">
            <template #cell(no)="data">
              <span>{{ data.index + 1 }}.</span>
            </template>
            <template #cell(kd_produk)="data">
              <div class="d-flex flex-column">
                <span class="font-weight-bolder">{{ data.item.name }}</span>
                <span style="font-size: smaller">{{
                  data.item.kd_produk
                }}</span>
              </div>
            </template>
            <template #cell(total)="data">
              {{ formatCurrency(calcTotal(data.item)) }}
            </template>
            <template #cell(images)="data">
              <div class="d-flex justify-content-end">
                <div v-viewer="{ moveable: false }" class="images">
                  <b-avatar v-for="(image, index) in data.item.images" :key="image.path" variant="info"
                    :src="`${config.apiUrl}/${image.path}`" :style="`${index > 0
                      ? 'border: 2px solid white; margin-left: -20px; '
                      : ''
                      }`" />
                </div>
              </div>
            </template>
            <template #cell(actions)="data">
              <div class="w-100 d-flex justify-content-center">
                <feather-icon id="fullfilment-button" icon="PackageIcon" size="16" class="align-middle text-body"
                  style="cursor: pointer" @click="openFullFillmentModal(data.item)" />
                <!-- <feather-icon id="refund-button" icon="DollarSignIcon" size="16" class="align-middle text-body ml-1"
                  style="cursor: pointer" @click="showRefundModal()" /> -->
                <b-tooltip target="fullfilment-button" triggers="hover">
                  Fullfilment
                </b-tooltip>
                <!-- <b-tooltip target="refund-button" triggers="hover">
                  Refund
                </b-tooltip> -->
              </div>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
    <div v-else style="height: 100%" />
    <b-modal v-model="rejectModal" centered title="Cancel Order" scrollable ok-title="Iya" cancel-title="Tidak"
      no-close-on-backdrop @ok="doCancelOrder">
      <h5>Apakah anda yakin ingin mereject Retur Order ini?</h5>
      <template #modal-footer="{ cancel, ok }">
        <b-button :disabled="rejectLoading" @click="cancel"> Tidak </b-button>
        <b-button :disabled="rejectLoading" variant="primary" @click="ok"><b-spinner v-if="rejectLoading" small
            class="mr-1" /><span>Iya</span></b-button>
      </template>
    </b-modal>
    <b-modal v-model="confirmModal" centered title="Konfirmasi/Proses" scrollable ok-title="Iya" cancel-title="Tidak"
      no-close-on-backdrop @ok="doConfirmRetur">
      <h5>Apakah anda yakin ingin memproses Retur Order ini?</h5>
      <template #modal-footer="{ cancel, ok }">
        <b-button :disabled="confirmLoading" @click="cancel"> Tidak </b-button>
        <b-button :disabled="confirmLoading" variant="primary" @click="ok"><b-spinner v-if="confirmLoading" small
            class="mr-1" /><span>Iya</span></b-button>
      </template>
    </b-modal>
    <b-modal v-model="receiveModal" centered title="Receive As Stock" scrollable ok-title="Submit" cancel-title="Cancel"
      no-close-on-backdrop @ok="doProcess">
      <h5>Pilih warehouse untuk receive sebagai stock</h5>
      <validation-observer #default="{ handleSubmit }" ref="refReceiveObserver">
        <b-form @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetReceiveForm">
          <validation-provider #default="validationContext" name="warehouse" rules="required">
            <b-form-group label="Warehouse" label-for="warehouse">
              <v-select id="warehouse" v-model="receiveForm.warehouse_id" label="warehouse_name" :filterable="false"
                :options="wareHousesData" :reduce="e => {
                  return e.id
                }
                  " @search="warehouseOnSearch">
                <template slot="option" slot-scope="option">
                  <div class="d-center">
                    {{ option.warehouse_name }}
                  </div>
                </template>
                <template slot="selected-option" slot-scope="option">
                  <div class="selected d-center">
                    {{ option.warehouse_name }}
                  </div>
                </template>
              </v-select>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-form>
      </validation-observer>
      <template #modal-footer="{ cancel, ok }">
        <b-button :disabled="processLoading" @click="cancel"> Tidak </b-button>
        <b-button :disabled="processLoading" variant="primary" @click="ok"><b-spinner v-if="processLoading" small
            class="mr-1" /><span>Iya</span></b-button>
      </template>
    </b-modal>
    <b-modal v-model="fullfilmentModal" centered title="Fullfillment" scrollable ok-title="Submit" cancel-title="Cancel"
      no-close-on-backdrop @ok="doFullfillment">
      <validation-observer #default="{ handleSubmit }" ref="refFullfillmentObserver">
        <b-form @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="fullFillmentResetForm">
          <validation-provider #default="validationContext" name="Kode Produk" rules="required">
            <b-form-group label="Kode Produk" label-for="kode-produk">
              <b-form-input id="kode-produk" v-model="fullfillmentFormTemp.kd_produk" label="Kode Produk" readonly />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider #default="validationContext" name="Return Quantity" rules="required">
            <b-form-group label="Return Quantity" label-for="return-qunatity">
              <b-form-input id="return-qunatity" v-model="fullfillmentFormTemp.quantity" label="Return Quantity"
                readonly />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider #default="validationContext" name="Fullfillment Quantity" rules="required">
            <b-form-group label="Fullfillment Quantity" label-for="fullfillment-quantity">
              <b-form-input id="fullfillment-quantity" v-model="fullfillmentFormTemp.fullfillment_quantity_retur"
                label="Fullfillment Quantity" type="number" :max="fullfillmentFormTemp.quantity"
                :formatter="fullFillmentQuantityFormatter" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-form>
      </validation-observer>
      <template #modal-footer="{ cancel, ok }">
        <b-button :disabled="fullfillmentLoading" @click="cancel">
          Tidak
        </b-button>
        <b-button :disabled="fullfillmentLoading" variant="primary" @click="ok"><b-spinner v-if="fullfillmentLoading"
            small class="mr-1" /><span>Iya</span></b-button>
      </template>
    </b-modal>
    <b-modal v-model="refundModal" centered title="Refund" scrollable ok-title="Iya" cancel-title="Tidak"
      no-close-on-backdrop @ok="doRefund">
      <h5 class="mb-2">Apakah anda yakin ingin melakukan Proses Refund?</h5>
      <ul class="list-unstyled">
        <b-media v-for="(item, index) in orderDetail" :key="item.kd_produk" tag="li"
          :class="`${index < orderDetail.length ? 'mb-2' : ''}`">
          <template #aside>
            <b-img :src="`${config.apiUrl}/${item.product.images[0].path}`" width="64" alt="placeholder" />
          </template>
          <div class="d-flex">
            <div class="d-flex flex-column">
              <h5 class="my-0" style="font-weight: 700; font-size: small">
                {{ item.kd_produk }}
              </h5>
              <p class="my-0" style="font-size: small">
                {{ item.product.nama_produk }}
              </p>
              <p class="my-0" style="font-size: small">
                {{ formatCurrency(item.price) }} x{{
                  getReturQuantity(item.kd_produk)
                }}
              </p>
            </div>
            <div class="d-flex flex-column justify-content-center align-item-end ml-4">
              <span>{{
                formatCurrency(
                  parseInt(item.price) * getReturQuantity(item.kd_produk)
                )
              }}</span>
            </div>
          </div>
        </b-media>
      </ul>
      <div class="d-flex justify-content-between">
        <p style="font-size: small; font-weight: bold">Total</p>
        <p style="font-size: small; font-weight: bold">
          {{ formatCurrency(getRefundTotal()) }}
        </p>
      </div>
      <template #modal-footer="{ cancel, ok }">
        <b-button :disabled="confirmLoading" @click="cancel"> Tidak </b-button>
        <b-button :disabled="confirmLoading" variant="primary" @click="ok"><b-spinner v-if="refundLoading" small
            class="mr-1" /><span>Iya</span></b-button>
      </template>
    </b-modal>
    <shipment-side-form :is-send-shipment-active.sync="showShipmentModal" :is-loading="loadingShipmentSend"
      @onSubmit="shipmentSubmit" />
  </b-overlay>
</template>
<script>
import 'viewerjs/dist/viewer.css'
import VueViewer, { directive as viewer } from 'v-viewer'
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BCardTitle,
  BOverlay,
  BButton,
  BSpinner,
  BFormCheckbox,
  BBadge,
  BLink,
  BAvatar,
  BForm,
  BFormGroup,
  BFormInvalidFeedback,
  BTooltip,
  BMedia,
  BImg,
} from 'bootstrap-vue'
import { computed, onMounted, ref, watch } from '@vue/composition-api'
import { formatCurrency, title, formatDate } from '@/@core/utils/filter'
import {
  typeVoucherOptions,
  statusOrder,
  statusOrderVariant,
} from '@core/utils/constant'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

import config from '@/config/config'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import service from '@/components/Table/service'
import { required } from '@validations'
import useReturnSale from './useReturnSale'
import ShipmentSideForm from './components/ShipmentSideForm.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BCardTitle,
    BOverlay,
    BButton,
    BSpinner,
    AppTimeline,
    AppTimelineItem,
    BFormCheckbox,
    BBadge,
    BLink,
    BAvatar,
    VueViewer,
    viewer,
    vSelect,
    ValidationObserver,
    ValidationProvider,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BTooltip,
    BMedia,
    BImg,
    ShipmentSideForm
  },
  directives: {
    viewer: viewer({
      debug: true,
    }),
  },
  data() {
    return {
      required,
    }
  },
  methods: {
    warehouseOnSearch(search, loading) {
      this.warehouseFilter(search, loading, this)
    },
    warehouseFilter: _.debounce((search, loading, vm) => {
      loading(true)
      service
        .index({
          url: 'warehouse-commerce',
          query: `sort_by=warehouse_name&direction=asc&q=${search}`,
        })
        .then(res => {
          vm.wareHousesData = res.data.data
          console.log(vm)
        })
        .finally(() => {
          loading(false)
        })
    }, 1000),
  },
  setup(props, context) {
    const refReceiveObserver = ref(null)
    const refFullfillmentObserver = ref(null)
    const {
      returnOrderDetail,
      calcTotal,
      subTotal,
      total,
      isLoading,
      shipmentSubmit,
      loadingShipmentSend,
      getTrackingHistory,
      trackingHIstoryData,
      trackingModal,
      cancelOrder,
      rejectLoading,
      rejectModal,
      refund,
      refundLoading,
      refundModal,
      receiveAsStock,
      processLoading,
      receiveModal,
      showShipmentModal,
      wareHousesData,
      resetReceiveForm,
      receiveForm,
      confirmModal,
      confirmRetur,
      confirmLoading,
      fullfillment,
      fullfillmentFormTemp,
      fullfillmentLoading,
      fullfilmentModal,
      fullFillmentResetForm,
      returnOrderDetailTemp,
      showRefundModal,
      orderDetail,
    } = useReturnSale(context.root.$route.params.id)
    const itemsColumn = ref([
      {
        label: 'No.',
        key: 'no',
        thClass: 'number_column_class',
      },
      {
        label: 'Kode Produk',
        key: 'kd_produk',
      },
      {
        label: 'Kuantiti',
        key: 'quantity',
      },
      {
        label: 'Fullfillment Kuantiti',
        key: 'fullfillment_quantity_retur',
      },
      {
        label: 'Alasan',
        key: 'reason',
      },
      {
        label: 'Images',
        key: 'images',
        thStyle: {
          'max-width': '100px',
        },
      },
      {
        label: 'Action',
        key: 'actions',
        thStyle: {
          width: '50px',
        },
      },
    ])

    const cancelOrderForm = ref({
      is_refund: false,
      order_id: context.root.$route.params.id,
    })
    const doCancelOrder = evt => {
      evt.preventDefault()
      cancelOrder(cancelOrderForm.value)
    }

    const doRefund = evt => {
      evt.preventDefault()
      refund({ id: context.root.$route.params.id })
    }

    const doProcess = evt => {
      evt.preventDefault()
      refReceiveObserver.value.validate().then(success => {
        if (success) {
          receiveForm.value.return_sale_id = context.root.$route.params.id
          receiveAsStock(receiveForm.value)
        }
      })
    }

    const doConfirmRetur = evt => {
      evt.preventDefault()
      confirmRetur(context.root.$route.params.id)
    }

    const waNumberFormat = number => {
      let result = number.slice(1)
      result = '62'.concat(result)
      return result
    }

    const receiveFormValidation = formValidation(resetReceiveForm)

    const openFullFillmentModal = data => {
      fullfillmentFormTemp.value = data
      fullfilmentModal.value = true
    }
    const doFullfillment = evt => {
      evt.preventDefault()
      fullfillment(fullfillmentFormTemp.value)
    }

    const fullFillmentQuantityFormatter = value => {
      console.log(
        value,
        parseInt(value),
        fullfillmentFormTemp.value.quantity,
        parseInt(value) > fullfillmentFormTemp.value.quantity
      )
      if (parseInt(value) > fullfillmentFormTemp.value.quantity) {
        return fullfillmentFormTemp.value.quantity
      }
      return value
    }

    const canSend = computed(() => {
      console.log(returnOrderDetailTemp)
      if (
        returnOrderDetailTemp.value.details.some(
          e =>
            e.fullfillment_quantity_retur === 0 ||
            e.fullfillment_quantity_retur === '0' ||
            e.fullfillment_quantity_retur === ''
        )
      ) {
        return false
      }
      return true
    })

    const getReturQuantity = kdProduk =>
      parseInt(
        returnOrderDetail.value.details.find(el => el.kd_produk === kdProduk)
          .quantity
      )

    const getRefundTotal = () => {
      let total = 0
      if (returnOrderDetail.value !== null && orderDetail.value !== null) {
        returnOrderDetail.value.details.forEach((el, index) => {
          const orderItem = orderDetail.value.find(
            item => item.kd_produk === el.kd_produk
          )
          total += parseInt(orderItem.price) * parseInt(el.quantity)
        })
      }
      return total
    }

    return {
      config,
      returnOrderDetail,
      itemsColumn,
      formatCurrency,
      calcTotal,
      title,
      formatDate,
      subTotal,
      total,
      isLoading,
      showShipmentModal,
      loadingShipmentSend,
      shipmentSubmit,
      trackingModal,
      trackingHIstoryData,
      rejectModal,
      cancelOrderForm,
      doCancelOrder,
      rejectLoading,
      refundLoading,
      refundModal,
      doRefund,
      statusOrder,
      statusOrderVariant,
      doProcess,
      processLoading,
      receiveModal,
      waNumberFormat,
      receiveForm,
      wareHousesData,
      refReceiveObserver,
      receiveFormValidation,
      confirmModal,
      doConfirmRetur,
      confirmLoading,
      fullfillmentLoading,
      fullfilmentModal,
      doFullfillment,
      fullfillmentFormTemp,
      refFullfillmentObserver,
      fullFillmentResetForm,
      openFullFillmentModal,
      fullFillmentQuantityFormatter,
      canSend,
      showRefundModal,
      orderDetail,
      getReturQuantity,
      getRefundTotal,
    }
  },
}
</script>
<style lang="scss">
.number_column_class {
  width: 50px;
}
</style>
