<template>
  <b-sidebar id="add-new-user-sidebar" :visible="isSendShipmentActive" bg-variant="white" sidebar-class="sidebar-lg"
    shadow backdrop no-header right @hidden="resetForm" @change="val => $emit('update:is-send-shipment-active', val)">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{
            typeForm === 'add'
            ? 'Add New'
            : typeForm === 'edit'
              ? 'Edit'
              : 'Detail'
          }}
        </h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>
      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
          <validation-provider #default="validationContext" name="warehouse" rules="required">
            <b-form-group label="Warehouse" label-for="warehouse">
              <v-select id="warehouse" v-model="warehouseId" label="warehouse_name" :filterable="false"
                :options="wareHousesData" :reduce="e => {
                  return e.id
                }
                  " @search="warehouseOnSearch">
                <template slot="option" slot-scope="option">
                  <div class="d-flex">
                    {{ option.warehouse_name }}
                  </div>
                </template>
                <template slot="selected-option" slot-scope="option">
                  <div class="selected d-center">
                    {{ option.warehouse_name }}
                  </div>
                </template>
              </v-select>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider #default="validationContext" name="couriers" rules="required">
            <b-form-group label="couriers" label-for="couriers">
              <v-select id="couriers" v-model="courier" label="Courier" :filterable="false" :options="courierOptions" :loading="courierLoading">
                <template slot="option" slot-scope="option">
                  <div class="d-center">
                    <div class="d-flex justify-content-between">
                      <div class="d-flex flex-column">
                        <span>
                          {{ option.courier_name }} ({{ option.courier_service_name }})
                        </span>
                        <small>{{ option.duration }}</small>
                      </div>
                      <span>
                        {{ formatCurrency(option.price) }}
                      </span>
                    </div>
                  </div>
                </template>
                <template slot="selected-option" slot-scope="option">
                  <div class="selected d-center">
                    {{ option.courier_name }} ({{ option.courier_service_name }})
                  </div>
                </template>
              </v-select>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider #default="validationContext" name="Delivery Type" rules="required">
            <b-form-group label="Delivery Type">
              <b-form-radio-group>
                <b-form-radio v-model="shipment.delivery_type" name="delivery_type" value="now">Now</b-form-radio>
                <b-form-radio v-model="shipment.delivery_type" name="delivery_type"
                  value="scheduled">Scheduled</b-form-radio>
              </b-form-radio-group>

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider v-if="shipment.delivery_type === 'scheduled'" #default="validationContext"
            name="Delivery Date" :rules="shipment.delivery_type === 'scheduled' ? 'required' : ''">
            <b-form-group label="Delivery Date" :state="getValidationState(validationContext)">
              <date-picker v-model="shipment.delivery_date" value-type="YYYY-MM-DD" name="delivery_date" class="mb-1"
                placeholder="Delivery Date" />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider v-if="shipment.delivery_type === 'scheduled'" #default="validationContext"
            name="Delivery Time" :rules="shipment.delivery_type === 'scheduled' ? 'required' : ''">
            <b-form-group label="Delivery Time" :state="getValidationState(validationContext)">
              <date-picker v-model="shipment.delivery_time" name="delivery_time" class="mb-1" type="time"
                placeholder="Delivery Time" />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider #default="validationContext" name="Note" rules="">
            <b-form-group label="Note" label-for="note">
              <b-form-input id="note" v-model="shipment.note" autofocus :state="getValidationState(validationContext)"
                trim placeholder="Note" :readonly="!canEdit" />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Form Actions -->
          <div v-if="typeForm !== 'show'" class="d-flex mt-2">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit"
              :disabled="isLoading">
              <span>Submit</span>
              <b-spinner v-if="isLoading" small class="ml-1" />
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary" @click="hide">
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BModal,
  BFormSelect,
  BFormDatepicker,
  BSpinner,
  BFormCheckbox,
  BFormTextarea,
  BFormRadio,
  BFormRadioGroup,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import inject, { onMounted, ref, watch } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import { formatCurrency } from '@/@core/utils/filter'
import service from '@/components/Table/service'
import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { createToastInterface } from 'vue-toastification'
import { serializeErrorMessage } from '@core/utils/utils'

export default {
  components: {
    Password: () => import('vue-password-strength-meter'),
    DatePicker: () => import('vue2-datepicker'),
    BSidebar,
    BForm,
    BModal,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormInvalidFeedback,
    BFormDatepicker,
    BButton,
    vSelect,
    BSpinner,
    BFormCheckbox,
    BFormTextarea,
    BFormRadio,
    BFormRadioGroup,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isSendShipmentActive',
    event: 'update:is-send-shipment-active',
  },
  props: {
    isSendShipmentActive: {
      type: Boolean,
      required: true,
    },
    typeForm: {
      type: String,
      default: 'add',
    },
    data: {
      type: Object,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
      warehouseOptions: [],
    }
  },
  methods: {
    warehouseOnSearch(search, loading) {
      this.warehouseFilter(search, loading, this)
    },
    warehouseFilter: _.debounce((search, loading, vm) => {
      loading(true)
      service
        .index({
          url: 'warehouse-commerce',
          query: `sort_by=warehouse_name&direction=asc&q=${search}`,
        })
        .then(res => {
          vm.wareHousesData = res.data.data
          console.log(vm)
        })
        .finally(() => {
          loading(false)
        })
    }, 1000),
  },
  setup(props, { emit, root }) {
    const blankFormData = {
      delivery_type: 'now',
      delivery_date: null,
      delivery_time: null,
      note: null,
    }

    const canEdit = ref(true)
    const wareHousesData = ref([])

    const shipment =
      props.data !== null
        ? ref(props.data)
        : ref(JSON.parse(JSON.stringify(blankFormData)))

    const resetpageData = () => {
      shipment.value = JSON.parse(JSON.stringify(blankFormData))
    }

    watch(
      () => props.isSendShipmentActive,
      (newValue, prevValue) => {
        if (props.typeForm === 'edit') {
          canEdit.value = true
          shipment.value = props.data
        } else if (props.typeForm === 'show') {
          canEdit.value = false
          shipment.value = props.data
        } else {
          canEdit.value = true
          shipment.value = JSON.parse(JSON.stringify(blankFormData))
        }
      }
    )

    const onSubmit = () => {
      emit('onSubmit', shipment.value)
    }

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetpageData)

    const warehouseId = ref(null)

    const courier = ref(null)
    const courierOptions = ref([])
    const courierLoading = ref(false)
    const fetchCourierRates = () => {
      courierLoading.value = true
      service.store({ url: 'return-sale-couriers', data: { warehouse_id: warehouseId, return_sale_id: root.$route.params.id } }).then(res => {
        courierOptions.value = res.data.pricing
      }).catch(error => {
        const element = Vue.component('description', {
          render: h => serializeErrorMessage(h, error),
        })
        const toast = createToastInterface({
          timeout: 6000,
        })
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error Fetch Courier Options',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            description: element,
          },
        })
      }).finally(() => {
        courierLoading.value = false
      })
    }

    onMounted(() => {
      service
        .index({
          url: 'warehouse-commerce',
          query: 'sort_by=warehouse_name&direction=asc',
        })
        .then(res => {
          wareHousesData.value = res.data.data
        })
    })

    watch(warehouseId, (val) => {
      // console.log(val)
      if (val) {
        fetchCourierRates()
      }
    })

    return {
      tglLahir: null,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      canEdit,
      props,

      shipment,
      warehouseId,
      wareHousesData,
      courier,
      courierOptions,
      formatCurrency,
      courierLoading
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
